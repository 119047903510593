<!--
--------------------------------------------------------------------------------
<copyright file="QuotesConversionRatesGauge.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.outer_arc') }}</summary>
      <span class="demi-bold">{{ $t('widget_info_text.quotes_conv_rates_amount') }}</span>
      : {{ $t('widget_info_text.quotes_conv_rates_amount_txt') }}
    </details>
    <details>
      <summary>{{ $t('widget_info_text.inner_arc') }}</summary>
      <span class="demi-bold">{{ $t('widget_info_text.quotes_conv_rates_quotes') }}</span>
      : {{ $t('widget_info_text.quotes_conv_rates_quotes_txt') }}
    </details>
    <details>
      <summary>{{ $t('widget_info_text.clock_hand') }}</summary>
      <p class="text">{{ $t('widget_info_text.clock_hand_conv_rates_txt') }}</p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class QuotesConversionRatesDetailedWidgetInfo extends Vue {}
</script>
